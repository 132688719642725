import { configureStore } from '@reduxjs/toolkit';

const initialUser = {
  invites: {},
  unrespondedInvites: [],
  inviteCategories: [],
  feed: [],
  originalFeed: [],
  publicEvent: false,
  invitePeople: {},
  loading: true,
  selectedUsers: [],
};
function userReducer(user = initialUser, action) {
  switch (action.type) {
    case 'SET_LOADING': {
      return { ...user, loading: action.payload };
    }
    case 'USER_LOADED': {
      const inviteMap = {};
      let invitePeopleMap = user.invitePeople;
      if (action.payload.invites) {
        invitePeopleMap = {};
        action.payload.invites.forEach((i) => {
          invitePeopleMap[i.group_invite_uuid] = i.people;
          inviteMap[i.uuid] = i;
        });
      }

      return {
        ...user,
        ...action.payload,
        originalFeed: action.payload.feed,
        invitePeople: invitePeopleMap,
        invites: inviteMap,
        unrespondedInvites: action.payload.unrespondedInvites,
        inviteCategories: action.payload.inviteCategories,
        loading: false,
      };
    }
    case 'USER_UPDATED': {
      return { ...user, ...action.payload };
    }
    case 'FEED_FILTERED': {
      return { ...user, feed: action.payload };
    }
    case 'FEED_RESET': {
      const feed = user.originalFeed ? user.originalFeed : [];
      return { ...user, feed: feed };
    }
    case 'PUBLIC_EVENT_SELECTED': {
      return { ...user, publicEvent: action.payload };
    }
    case 'INVITE_UPDATED': {
      const inviteUuid = action.payload.uuid;
      const newInvites = { ...user.invites, [inviteUuid]: action.payload };
      const invitePeopleMap = {};
      Object.values(newInvites).forEach((i) => {
        invitePeopleMap[i.group_invite_uuid] = i.people;
      });

      return { ...user, invites: newInvites, invitePeople: invitePeopleMap };
    }
    case 'ADD_USERS_TO_GROUP_INVITE': {
      const groupInviteUuid = action.payload.groupInviteUuid;
      const invitePeopleMap = [...user.invitePeople[groupInviteUuid], ...action.payload.group];
      return {
        ...user,
        invitePeople: { ...user.invitePeople, [groupInviteUuid]: invitePeopleMap },
      };
    }
    case 'UPDATE_INVITE_REQUIRED_PARTICIPANTS': {
      const inviteUuid = action.payload.uuid;
      const newInvites = {
        ...user.invites,
        [inviteUuid]: {
          ...action.payload,
          requiredParticipants: action.payload.requiredParticipants,
        },
      };
      return { ...user, invites: newInvites };
    }
    case 'ADD_USER_TO_NEW_INVITE': {
      const inviteUuid = action.payload.inviteUuid;
      const userUuid = action.payload.userUuid;
      const newInvites = {
        ...user.invites,
        [inviteUuid]: {
          ...user.invites[inviteUuid],
          requiredParticipants: [...user.invites[inviteUuid].requiredParticipants, userUuid],
        },
      };
      return { ...user, invites: newInvites };
    }
    case 'REMOVE_USER_FROM_NEW_INVITE': {
      const inviteUuid = action.payload.inviteUuid;
      const userUuid = action.payload.userUuid;
      const newInvites = {
        ...user.invites,
        [inviteUuid]: {
          ...user.invites[inviteUuid],
          requiredParticipants: user.invites[inviteUuid].requiredParticipants.filter(
            (p) => p !== userUuid
          ),
        },
      };
      return { ...user, invites: newInvites };
    }
    case 'NEW_INVITE_PEOPLE': {
      const groupInviteUuid = 0;
      const invitePeopleMap = { ...user.invitePeople, [groupInviteUuid]: action.payload.people };
      const invites = { ...user.invites, 0: { uuid: 0, requiredParticipants: [] } };
      return { ...user, invitePeople: invitePeopleMap, invites };
    }
    case 'logged_out': {
      return { ...user, jwt: false };
    }
    case 'INVITES_LOADED': {
      const inviteMap = {};
      const invitePeopleMap = {};
      action.payload.forEach((i) => {
        invitePeopleMap[i.group_invite_uuid] = i.people;
        inviteMap[i.uuid] = i;
      });
      return { ...user, invites: inviteMap, invitePeople: invitePeopleMap };
    }
    case 'UNRESPONDED_INVITES_LOADED': {
      return { ...user, unrespondedInvites: action.payload };
    }
    case 'LOCATION_CHANGED': {
      return { ...user, location: action.payload };
    }
    case 'INVITE_DELETED': {
      return { ...user, invites: user.invites.filter((i) => i.uuid !== action.payload) };
    }
    default: {
      return user;
    }
  }
}

const initialInvites = {};
function invitesReducer(invites = initialInvites, action) {
  switch (action.type) {
    case 'OPEN_INVITES_LOADED': {
      return action.payload;
    }
    default: {
      return invites;
    }
  }
}

const newInvite = {
  people: [],
  selectedLocations: [],
  selectedTimes: [],
  selectedHours: [],
};
function newInviteReducer(invite = newInvite, action) {
  switch (action.type) {
    case 'ADD_SELECTED_USER': {
      return { ...invite, people: [...invite.people, action.payload] };
    }
    case 'REMOVE_SELECTED_USER': {
      const userUuid = action.payload;
      return { ...invite, people: invite.people.filter((p) => p.uuid !== userUuid) };
    }
    case 'CLEAR_NEW_INVITE': {
      return { ...newInvite };
    }
    case 'LOCATION_SELECTED': {
      const selectedIds = invite.selectedLocations.map((l) => l.id);
      if (selectedIds.includes(action.payload.id)) {
        return {
          ...invite,
          selectedLocations: invite.selectedLocations.filter((l) => l.id !== action.payload.id),
        };
      }
      return { ...invite, selectedLocations: [...invite.selectedLocations, action.payload] };
    }
    case 'TIME_SELECTED': {
      const selectedIds = invite.selectedTimes.map((t) => t.time);
      if (selectedIds.includes(action.payload.time)) {
        return {
          ...invite,
          selectedTimes: invite.selectedTimes.filter((t) => t.time !== action.payload.time),
        };
      }
      return { ...invite, selectedTimes: [...invite.selectedTimes, action.payload] };
    }
    case 'HOUR_SELECTED': {
      const selectedIds = invite.selectedHours.map((h) => h.hour);
      if (selectedIds.includes(action.payload.hour)) {
        return {
          ...invite,
          selectedHours: invite.selectedHours.filter((h) => h.hour !== action.payload.hour),
        };
      }
      return { ...invite, selectedHours: [...invite.selectedHours, action.payload] };
    }
    case 'RESET_TIMES': {
      return { ...invite, selectedTimes: [], selectedHours: [] };
    }
    default: {
      return invite;
    }
  }
}

const rootReducer = {
  invites: invitesReducer,
  user: userReducer,
  newInvite: newInviteReducer,
};

const createStore = () => configureStore({ reducer: rootReducer });
export default createStore;
